<script>
import CustomerService from "@/services/CustomerService";
import MGConsts from "@/mixins/MGConsts";
import EcService from "@/services/EcService";

export default {
    name: "VOverview",
    props: {
        customer_id: {
            description: "Customer ID",
        },
    },
    mixins: [MGConsts],
    data() {
        return {
            loadingUpdate: false,
            loading: false,
            customer: null,
            layouts: null,
            termsOfPayment: null,
        };
    },
    async created() {
        this.ACL_RW = this.$aclCan(this.$ACL.CUSTOMER_EDIT);        
        this.loading = true;
        await this.fetchEconomicParams();
        await this.fetchData();
    },
    methods: {
        /**
         * Fetch customer info
         */
        async fetchData() {
            try {
                this.loading = true;
                const r = await CustomerService.GETS(this.customer_id);
                this.customer = r.data.data;
            } catch (e) {}
            this.loading = false;
        },
        /**
         * Fetch economic params
         */
        async fetchEconomicParams() {
            try {
                const r = await EcService.ecLayoutsAndTerms();
                this.layouts = r.data.data.layouts;
                this.layouts.unshift({
                    label: this.$t("ec.not_assigned"),
                    value: "",
                });

                this.termsOfPayment = r.data.data.terms_of_payment;
                this.termsOfPayment.unshift({
                    label: this.$t("ec.not_assigned"),
                    value: "",
                });
            } catch (e) {}
        },
        /**
         * Update customer
         */
        async updateCustomer() {
            try {
                this.loadingUpdate = true;
                let data = {
                    id: this.customer.id,
                    lang: this.customer.lang,
                    internal_customer: this.customer.internal_customer,
                    discount: this.customer.discount,
                };
                const r = await CustomerService.PUT(data);
                this.$notify({
                    message: this.$t("customer.data_saved"),
                    timeout: 5000,
                    icon: "far fa-check",
                    type: "success",
                });
            } catch (e) {}
            this.loadingUpdate = false;
        },
    },
};
</script>

<template>
    <div class="container-fluid">
        <div class="row card-wrapper">
            <div class="col-12">
                <div class="mg-title mb-3">
                    <div class="mg-title__left"></div>
                    <div class="mg-title__right"></div>
                </div>
            </div>
        </div>
        <!-- headings -->

        <div class="row card-wrapper">
            <div class="col-12">
                <card body-classes="">
                    <v-loader :loading="loading" v-if="loading" />
                    <template v-else-if="customer !== null">
                        <h1>{{ customer.name }}</h1>

                        <div class="row">
                            <div class="col">
                                <dl>
                                    <dt>
                                        {{ $t("customer.customer_number") }}
                                    </dt>
                                    <dd>
                                        {{ customer.number }}
                                    </dd>

                                    <dt>{{ $t("customer.is_active") }}</dt>
                                    <dd>
                                        {{
                                            $matchInHash(
                                                mgGeneralActiveHash,
                                                customer.active
                                            )
                                        }}
                                    </dd>

                                    <dt>{{ $t("customer.vat_zone") }}</dt>
                                    <dd>
                                        {{
                                            $matchInHash(
                                                mgVatZonesHash,
                                                customer.vat_zone
                                            )
                                        }}
                                    </dd>

                                    <dt>{{ $t("customer.vat_number") }}</dt>
                                    <dd>{{ customer.vat_number }}</dd>

                                    <dt>
                                        {{ $t("customer.default_currency") }}
                                    </dt>
                                    <dd>{{ customer.default_currency }}</dd>

                                    <dt>{{ $t("customer.default_layout") }}</dt>
                                    <dd>
                                        {{ $matchIn(layouts, customer.layout) }}
                                    </dd>

                                    <dt>
                                        {{
                                            $t("customer.default_payment_terms")
                                        }}
                                    </dt>
                                    <dd>
                                        {{
                                            $matchIn(
                                                termsOfPayment,
                                                customer.term_of_payment
                                            )
                                        }}
                                    </dd>

                                    <dt>{{ $t("customer.address") }}</dt>
                                    <dd>
                                        {{ customer.address }}<br />
                                        {{ customer.postal_code }}
                                        {{ customer.city }}
                                        <br v-if="customer.country" />
                                        {{ customer.country }}
                                    </dd>

                                    <dt>{{ $t("customer.telephone") }}</dt>
                                    <dd>{{ customer.telephone }}</dd>
                                </dl>
                            </div>

                            <div class="col">
                                <FormulateInput
                                    :disabled="!ACL_RW"
                                    v-if="customer.internal_customer == 0"
                                    v-model="customer.discount"
                                    :label="$t('customer.discount')"
                                    :help="$t('customer.discount_help')"
                                    type="groupdecimal"
                                    append="%"
                                    error-behavior="live"
                                    validation="number|min:0|max:100"
                                    :validation-name="$t('customer.discount')"
                                />

                                <FormulateInput
                                    :disabled="!ACL_RW"
                                    v-model="customer.lang"
                                    :label="$t('customer.language')"
                                    :options="mgLangs"
                                    :help="$t('customer.language_help')"
                                    type="select"
                                />

                                <FormulateInput
                                    :disabled="!ACL_RW"
                                    v-model="customer.internal_customer"
                                    :label="$t('customer.customer_type')"
                                    :options="mgInternalCustomer"
                                    :help="
                                        $t('customer.internal_customer_help')
                                    "
                                    type="select"
                                />
                                <div class="text-right" v-if="ACL_RW">
                                    <base-button
                                        type="primary"
                                        @click="updateCustomer"
                                        :loading="loadingUpdate"
                                    >
                                        {{ $t("customer.save_settings") }}
                                    </base-button>
                                </div>
                            </div>
                            <!-- .col -->
                        </div>
                    </template>
                </card>
            </div>
        </div>
    </div>
</template>