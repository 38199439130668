import Http from "@/libraries/Http"; 

export default {
    apiVersion: 'v1', 
    RESTroute: 'contracts',

    async GET_statisticsContractDetails(params = {}) {
        return Http.get(`/${this.apiVersion}/${this.RESTroute}/statistics-contract-details`, {params: params}); 
    },

    async GET_statistics(params = {}) {
        return Http.get(`/${this.apiVersion}/${this.RESTroute}/statistics`, {params: params}); 
    },

    /**
     * Get defauilt fields for project creation from contract data
     * @param {G} contract_id 
     * @param {*} params 
     * @returns 
     */
    async GET_defaultsForContract(contract_id, params = {}) {
        return Http.get(`/${this.apiVersion}/${this.RESTroute}/defaults-for-contract/${contract_id}`, {params: params}); 
    },

    /**
     * Get coming termination dates (next_termination_dates)
     * @param {} params 
     * @returns 
     */
    async GET_terminations(params = {}) {
        return Http.get(`/${this.apiVersion}/${this.RESTroute}/terminations`, {params: params}); 
    }, 

    /** 
     * Edit-replace a contract. 
     * We are creating a new contract here and replacing the old one 
     * (new version)
     * 
     * Payload: {}
     */
    async POST_EditReplace(old_contract_id, data) {
        return Http.post(`/${this.apiVersion}/${this.RESTroute}/edit-replace/${old_contract_id}`, data);
    },

    /** 
     * Get all resources 
     * GET /resource
     */
    async GET(params = {}) {
        return Http.get(`/${this.apiVersion}/${this.RESTroute}`, {params: params}); 
    }, 

    async GET_AllForCustomer(params = {}) {
        return Http.get(`/${this.apiVersion}/${this.RESTroute}/for-customer`, {params: params}); 
    },

    /**
     * Get contract revisions
     * @param {*} params 
     */
    async GET_Revisions(contract_id, params = {}) {
        return Http.get(`/${this.apiVersion}/${this.RESTroute}/revisions/${contract_id}`, {params: params}); 
    }, 
     
    /** 
     * Get single resource
     * GET /resource/<id>
     */
    async GETS(id, params = {}) {
        return Http.get(`/${this.apiVersion}/${this.RESTroute}/${id}`, {params: params}); 
    }, 

    /** 
     * Create resource
     * POST /resource 
     * Payload: {}
     */
    async POST(data) {
        return Http.post(`/${this.apiVersion}/${this.RESTroute}`, data);
    },

    /** 
     * Put resource
     * PUT /resource/<id>
     * Payload: {}
     */
    async PUT(data) {        
        return Http.put(`/${this.apiVersion}/${this.RESTroute}/${data.id}`, data);
    }, 

    async PUT_Override(data) {        
        return Http.put(`/${this.apiVersion}/${this.RESTroute}/${data.id}?override=true`, data);
    }, 

    /** 
     * Delete resource
     * DELETE /resource/<id>
     */
    async DELETE(id) {
        return Http.delete(`/${this.apiVersion}/${this.RESTroute}/${id}`);
    }
    
}